<template>
  <div class="patentRights">
    <nf-breadcrumb :breadList="[{ name: '知识产权' }, { name: '专利维权' }]"></nf-breadcrumb>
    <div class="imgBox">
      <img src="@/assets/images/property/u840.jpg" alt="" />
    </div>
    <div class="title">维权通道</div>
    <el-form ref="ruleForm" label-position="left" :model="formData" :rules="rules" label-width="80px" class="demo-ruleForm">
      <el-row>
        <el-col :span="11">
          <el-form-item label="名称" prop="name">
            <el-input v-model="formData.name" placeholder="请输入个人或企业名称"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="11" :offset="2">
          <el-form-item label="标题" prop="title">
            <el-input v-model="formData.title" placeholder="请输入服务标题"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="11">
          <el-form-item label="联系人" prop="contacts">
            <el-input v-model="formData.contacts" placeholder="请输入联系人姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11" :offset="2">
          <el-form-item label="手机号" prop="phoneNumber">
            <el-input v-model.number="formData.phoneNumber" placeholder="请输入手机号"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="11">
          <el-form-item label="邮箱" prop="emailAddress">
            <el-input v-model="formData.emailAddress" placeholder="请输入邮箱地址"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="留言" prop="content">
        <el-input
          type="textarea"
          v-model="formData.content"
          :autosize="{ minRows: 10, maxRows: 100 }"
          placeholder="请输入申请内容"
        ></el-input>
      </el-form-item>

      <el-form-item class="subWrap">
        <el-button type="primary" @click="handleSubmit">立即创建</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import { Message } from 'element-ui';

export default {
  name: 'patentRights',
  data() {
    const rulesPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'));
      } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error('请输入正确的手机号'));
        }
      }
    };
    const rulesEmail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('邮箱不能为空'));
      } else {
        const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error('请输入正确的邮箱'));
        }
      }
    };
    return {
      formData: {
        name: '',
        title: '',
        contacts: '',
        phoneNumber: '',
        emailAddress: '',
        content: ''
      },
      rules: {
        name: [{ required: true, message: '请输入个人或企业名称', trigger: 'blur' }],
        title: [{ required: true, message: '请输入服务标题', trigger: 'blur' }],
        contacts: [{ required: true, message: '请输入联系人姓名', trigger: 'blur' }],
        phoneNumber: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: rulesPhone, trigger: 'blur' }
        ],
        emailAddress: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: rulesEmail, trigger: 'blur' }
        ],
        content: [{ required: true, message: '请输入申请内容', trigger: 'blur' }]
      }
    };
  },
  methods: {
    handleSubmit() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          const api = this.$fetchApi.patentRights.api;
          const data = await this.$fetchData.fetchPost(this.formData, api, 'json');
          if (data.code === '200') {
            Message.success('申请成功, 请下载APP进行查看');
            setTimeout(() => {
              this.$router.push('/download');
            }, 1000);
          } else {
            Message.error(data.message);
          }
        } else {
          return false;
        }
      });
    }
  },
  components: { nfBreadcrumb }
};
</script>

<style lang="less" scoped>
.imgBox {
  width: 100%;
  img {
    display: block;
    width: 100%;
  }
}
.title {
  position: relative;
  background: #67b9d8;
  color: #fff;
  text-align: center;
  width: 180px;
  height: 70px;
  line-height: 70px;
  font-size: 25px;
  font-weight: 900;
  margin: 20px auto 80px auto;
}
.title::before {
  content: '';
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  background: #67b9d8;
  left: 75px;
  bottom: -15px;
  transform: rotate(45deg);
}
.demo-ruleForm {
  width: 1200px;
  margin: 0 auto;
}
.patentRights /deep/ .el-form-item__label {
  font-size: 18px;
}
.patentRights /deep/ .el-input__inner,
.patentRights /deep/ .el-textarea__inner {
  font-size: 16px;
}
.patentRights /deep/ .el-form-item__content {
  text-align: center;
}
.patentRights /deep/ .subWrap .el-form-item__content {
  margin-left: 0 !important;
}
.el-button {
  width: 200px;
  height: 50px;
}
.breadWrap {
  .el-breadcrumb {
    width: 1200px;
    margin: 20px auto;
    font-size: 16px;
  }
}
</style>
